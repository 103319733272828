
    import { defineComponent } from "vue";
    import AccountApi from '@/apis/AccountApi';
    import vm from '@/main';
    export default defineComponent({
        name: "Login",
        data() {
            return {
            account: '',
            hasAccount: true,
            password: '',
            hasPassword: true,
            loginMessage: '',
            };
        },
        mounted() {
            // auto login

            if (vm.$cookies.isKey('token')) {
            // redirect to Workspace and workspace (Manager.vue exactly) will redirect user to proper location
                this.$router.push({ path: "/workspace/course" });
            }
            else{
                vm.$store.commit('resetState');
                vm.$router.push({ path: "/" });
            }
        },
        methods: {
            reset() {
                this.account = '';
                this.password = '';
            },
            loginButton() {
                if (this.account !== '' && this.password !== '') {
                    this.hasPassword = true;
                    this.hasAccount = true;
                    this.handleLogin();
                }
                else {
                    if (this.account === '') {
                        this.hasAccount = false;
                    }
                    else {
                        this.hasAccount = true;
                    }
                    if (this.password === '') {
                        this.hasPassword = false;
                    }
                    else {
                        this.hasPassword = true;
                    }
                }
            },
            async handleLogin() {
                try {
                    const { profile, auth } = await AccountApi.login(this.account, this.password);
                    this.$cookies.set('token', auth.token);
                    this.$store.commit('saveProfile', profile);
                    console.log("profile.email = " + profile.email, "profile.name = " + profile.username);
                    const accountFolder = JSON.parse(JSON.stringify(await AccountApi.getAccountFolder()));
                    this.$cookies.set('sellPlanFolders', accountFolder.workspaceList[0].sellPlanFolders.local);
                    this.$cookies.set('quizFolders', accountFolder.workspaceList[0].quizFolders.local);
                    this.$cookies.set('courseFolders', accountFolder.workspaceList[0].courseFolders.local);

                    // await this.fetchFeatureFlags();
                    this.$router.push({ path: "/workspace/course" });
                } catch (err) {
                    // if (err && err.login-message && err.response.status === 401) {
                    if (err) {
                        this.loginMessage = '登入失敗，錯誤的帳號或密碼';
                        this.reset();
                        setTimeout(() => {
                            this.loginMessage = '';
                        }, 3000);
                    }
                    else {
                        console.error(err);
                    }
                }
            },
            // async fetchFeatureFlags() {
            //   // fetch all feature flags into store
            //   // expected to load feature flags once profile is ready
            //   try {
            //       if (this.$store.state.profile) {
            //       // const featureFlags = await FeatureFlagApi.fetchAllFeatureFlags(this.$store.state.profile.username);
            //       // this.$store.commit('updateFeatureFlags', featureFlags);
            //       }
            //   } catch (error) {
            //       console.error(error);
            //   }
            // },
        },
    });
